import React from 'react'
import styled from 'styled-components'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


const Container = styled.div`
  height: 55rem;
  width: 100%;
  display: flex;
  

  &:last-child {
    /* margin-bottom: 30rem; */
  }

  @media ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 2rem;
    height: auto;
  }
`

const InfoPanel = styled.div`
  width: 30%;
  text-align: center;
  order: 1;
  background-color: #E8E5E5;
  color: ${props => props.theme.colors.dark};
  padding: 2rem;

  .icon-container {
    height: 100%;
    margin: 2rem auto;
  }

  svg {
    margin: 6rem auto;
    height: 75%;
    /* max-width: 85%; */
  }
/* 
  p {
    width: 70%;
    margin: 0 auto;
    letter-spacing: 1px;
    line-height: 2.6rem;
  } */

  @media ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  @media ${props => props.theme.breakpoints.down('lg')} {

    .icon-container {
      height: 60%;
    }
  }
`

const ImageSlider = styled.div`
  width: 70%;
  order: ${props => !props.inverted ? 2 : -1};
  height: 100%;

  img {
    width: 100%;
    object-fit: cover;
    height: 55rem;
  }

  @media ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    
    img {
      height: 30rem;
    }
  }
`
 
export default ({inverted, Icon, details, images, id}) => {


 return (
 <Container>
  <InfoPanel>
    <div id={id} className="icon-container" dangerouslySetInnerHTML={{__html: Icon}}></div>
    </InfoPanel>
  <ImageSlider inverted={inverted}>
    <Carousel infiniteLoop showThumbs={false} showStatus={false} autoPlay={true}
    dynamicHeight={false}>
      {images.map(({image}, i) => (
        <div>
          <img key={i} src={image.childImageSharp.fluid.src} />
        </div>
      ))}
    </Carousel>
  </ImageSlider>
 </Container>
 )
}
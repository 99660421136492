import React from 'react'
import styled from 'styled-components'

import RowWrapper from '../../utils/rowWrapper';

const SectionHeader = styled.div`
  height: 20rem;
  text-align: center;
  margin-top: 8rem;
`

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.primary};
`

const SectionDescription = styled.p`
 width: 60%;
 margin: 0 auto;
 line-height: 2.5rem;
 letter-spacing: 1px;

 @media ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
`

const Line = styled.hr`
  height: 2px;
  background-color: ${props => props.theme.colors.dark};
  width: 30%;
  margin: 3rem auto;

  @media ${props => props.theme.breakpoints.down('md')} {
    width: 60%;
    display: none;
  }
`
 
export default ({header, description}) => {
 
 return (
   <RowWrapper>
    <SectionHeader>
      <SectionTitle>{header}</SectionTitle>
      <SectionDescription>{description}</SectionDescription>
      <Line />
    </SectionHeader>
  </RowWrapper>
 )
}
import React, { useEffect } from 'react';

import Layout from "../components/layout"
import styled from 'styled-components';
import RowWrapper from "../utils/rowWrapper";

import SectionHeader from '../components/gallery/SectionHeader';
import Carousel from '../components/gallery/carouselSection';

import gsap from "gsap";

const TopSection = styled.div`
  height: 45rem;
  background-color: ${props => props.theme.colors.dark};


  @media ${props => props.theme.breakpoints.down('xs')} {
    
    height: auto;
    width: 100%;
    padding: 2rem 2rem 5rem 2rem;
  }
`
const Header = styled.div`
  width: 60%;
  text-align: justify;
  padding-top: 20rem;

  @media ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    padding-top: 18rem;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-size: 2.6rem;
  word-spacing: 2rem;
  text-align: left;
  line-height: 3.5rem;
`

const HeaderContent = styled.p`
  color: ${props => props.theme.colors.body};
  display: block;
  margin-top: 3rem;
  letter-spacing: 1px;
  line-height: 3.5rem;

`

const Gallery = ({data}) => {

  const {title: mainTitle, description: mainDescription} = data.strapiGalleryTitle;

  const {edges} = data.allStrapiGallery;
  
  useEffect(() => {
    gsap.to('.logo', {autoAlpha: 1});

    edges.forEach((_, i) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: `#icon${i}`,
          start: 'top 65%',
          toggleActions: "play none none reverse"
        }
      })
      .fromTo(['path', 'polygon', 'line', 'ellipse', 'polyline'].map(x => `#icon${i} ${x}`), {drawSVG: "0% 0%"}, {drawSVG: "100%", duration: 1})
    })
  }, [])

  return (
  <Layout>
    <TopSection>
    <RowWrapper>
      <Header>
        <Title>{mainTitle}</Title>
        <HeaderContent>
          {mainDescription}
        </HeaderContent>
      </Header>
    </RowWrapper>
    </TopSection>

    {edges.map(({node}, i) => (
      <>
      <SectionHeader header={node.title}  description={node.description} />
      <Carousel id={"icon" + i} inverted={i % 2 === 1} Icon={node.icon} details={node.details} images={node.images} />
      </>
    )) }

  </Layout>
)
}

export const pageQuery = graphql`
  query GalleryQuery {
    strapiGalleryTitle {
    title
    description
  }
  allStrapiGallery {
    edges {
      node {
        title
        description
        handle
        icon
        details
        images {
          image {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
        }
      }
    }
  }
  }
  `

  export default Gallery;

